* {
    box-sizing: border-box;
}

@font-face {
    font-family: "Roboto-Regular";
    src: url("../fonts/Roboto-Regular.woff2") format("woff2"),
        url("../fonts/Roboto-Regular.woff") format("woff");
}

@font-face {
    font-family: "Roboto-Medium";
    src: url("../fonts/Roboto-Medium.woff2") format("woff2"),
        url("../fonts/Roboto-Medium.woff") format("woff");
}

@font-face {
    font-family: "Sego-Regular";
    src: url("../fonts/segoesc.woff2") format("woff2"),
        url("../fonts/segoesc.woff") format("woff");
}

@font-face {
    font-family: "Sego-Bold";
    src: url("../fonts/segoescb.woff2") format("woff2"),
        url("../fonts/segoescb.woff") format("woff");
}

html,
body,
.root,
.root-router-host {
    height: 100%;
}

.root>div:not(.loader-container) {
    display: flex;
    flex-direction: column;
    height: 100%;
}

[hidden] {
    display: none !important;
}

.file-field-hidden {
    display: none;
}

body {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    color: #666;
    background-color: #f7f7f9;
    overflow-x: hidden;
    margin: 0 auto;
}

body.app-type--webview {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

a {
    text-decoration: none;
}

button {
    background-color: transparent;
    border: none;
    padding: 0;
}

p {
    line-height: normal;
}

input {
    padding: 0;
    margin: 0;
}

.container {
    max-width: 480px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

.create-page {
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;
    padding: 30px 0 15px;

    p {
        text-align: center;
        line-height: 1.4;
        margin: 0 -10px 16px;

        @media all and (max-width: 540px) {
            margin: 0 0 16px;
        }
    }

    .container  {
        display: flex;
        flex-direction: column;
    }

    @media all and (max-height: 800px) and (orientation: landscape) {
        p {
            order: 7;
        }
    }

    @media all and (max-width: 540px) {
        p {
            order: 7;
        }
    }
}

.website-link {
    font-size: 14px;
    color: #666;
    text-decoration: underline;
}
.note-text {
    font-size: 14px;
    color: #666;
    max-width: 370px;
    margin: 0 auto;
}

.section-title {
    font-family: "Sego-Bold", cursive;
    font-size: 23px;
    color: #2c3945;
    text-align: center;
    line-height: 1.6;
    max-width: 280px;
    margin: 0 auto;

    span {
        text-transform: uppercase;
    }
}

.link-container {
    display: flex;
    justify-content: flex-end;
    order: 10;
}

.error-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f7f7f9;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    p {
        text-align: center;
        margin-bottom: 16px;
    }

    .btn-upload {
        flex-grow: inherit;
        max-width: 370px;
        width: 80%;
    }
}

/*slider*/
.slick-list,
.slick-slider {
    position: initial;
    display: block;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots button {
    font-size: 0;
    line-height: 0;
}

.slick-dots {
    li {
        position: relative;
        display: inline-block;
        width: 6px;
        height: 6px;
        cursor: pointer;
        border-radius: 50%;
        padding: 0;
        margin: 0 3px;
    }

    button {
        width: 6px;
        height: 6px;
        cursor: pointer;
        border-radius: 50%;
        padding: 0;
        margin: 0 3px;
        color: transparent;
        border: 0;
        outline: none;
        background: #e0e0e0;
    }

    .slick-active {
        button {
            background: #000;
        }
    }
}

.slick-slide {
    float: left;
    font-size: 0;
}

.create-page-slider {
    position: relative;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-top: -15px;

    .slide {
        width: 80%;
        max-width: 352px;
        padding-bottom: 50px;
        padding-top: 30px;
        margin: 0 auto;
    }

    img {
        width: 100%;
        display: block;
        border-radius: 20px;
        border: 10px solid #fff;
        box-shadow: 1px 2px 24px rgba(58, 80, 97, 0.1);
        margin: 0 auto;
    }

    .slick-dots {
        bottom: 21px;
        left: 50%;
        transform: translateX(-50%);
        display: flex !important;
        justify-content: center;
        align-items: center;

        li {
            width: 8px;
            height: 8px;
        }

        button {
            width: 8px;
            height: 8px;
            margin: 0;
            background-color: #999;
            border-radius: 8px;
        }

        .slick-active {
            width: 22px;

            button {
                width: 22px;
                border-radius: 8px;
                background-color: transparent;
                border: 2px solid #e84423;
            }
        }
    }
}

.icon-bamboo {
    width: 304px;
    position: absolute;
    bottom: 0;
    right: -152px;
    z-index: -5;
}

.result-page {
    height: 100%;
    padding: 30px 0 15px;

    .collage-container {
        height: 100%;
    }

    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }

    .imageview-host {
        flex-grow: 1;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-color: transparent;
        margin: 0 auto 20px;

        @media all and (min-height: 660px) and (orientation: landscape) {
            width: 80%;
            max-width: 300px;
        }

        @media all and (min-height: 770px) {
            width: 100%;
            max-width: 400px;
        }

        @media all and (min-height: 770px) {
            width: 100%;
            max-width: 400px;
        }

        @media all and (max-width: 430px) {
            width: 100%;
        }

        .loader-container {
            p {
                display: none;
            }
        }
    }

    .imageview-image {
        height: 100%;
        border-radius: 10px;
    }

    @media all and (min-height: 720px) and (max-width: 400px) {
        padding: 50px 0 15px;

        .imageview-host {
            max-width: 100%;
            width: 100%;
        }
    }
}

.btn-download {
    font-size: 20px;
    color: #fff;
    max-width: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    width: 80%;
    max-width: 370px;
    height: 56px;
    background-color: #0c0613;
    background-image: linear-gradient(34deg, rgba(37, 58, 75, 0.66) 0%, rgba(80, 125, 160, 0.66) 100%);
    border-radius: 10px;
    margin: 0 auto 16px;

    svg {
        fill: #fff;
        width: 23px;
        margin-right: 16px;
    }

    @media all and (max-width: 430px) {
        width: 100%;
    }
}

.btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    max-width: 370px;
    margin: 0 auto 16px;
    order: 5;

    @media all and (max-width: 430px) {
        width: 100%;
    }
}

.btn-upload {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 48px;
    color: #3f5c72;
    background-color: #fff;
    border: 1px solid #3f5c72;
    border-radius: 10px;

    svg {
        width: 34px;
        margin-right: 16px;
    }
}

.btn-share {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background-color: #fff;
    border: 1px solid #3f5c72;
    border-radius: 10px;
    width: 48px;
    flex-shrink: 0;
    margin-left: 12px;

    svg {
        width: 25px;
    }
}

.btn-select-photo {
    font-size: 20px;
    color: #fff;
    flex-grow: 1;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #be2609;
    background-image: linear-gradient(-170deg, rgba(234, 80, 50, 1) 0%, rgba(182, 31, 2, 1) 100%);
    border-radius: 10px;

    svg {
        fill: #fff;
        width: 36px;
        margin-right: 22px;
    }
}

.btn-crop-photo {
    background-color: #0c0613;
    background-image: linear-gradient(34deg, rgba(37, 58, 75, 0.66) 0%, rgba(80, 125, 160, 0.66) 100%);
    width: 80px;
    height: 56px;
    border-radius: 10px;
    margin-right: 12px;

    svg {
        width: 36px;
        fill: #fff;
    }
}

.w-button {
    display: flex;
    align-items: flex-end;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;

    button,
    a {
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        line-height: 44px;
        letter-spacing: 0.48px;
        display: inline-block;
        overflow: hidden;
        height: 44px;
        width: 100%;
        max-width: 230px;
        border-radius: 44px;
        box-shadow: 0 10px 10px 0 rgba(0, 145, 255, 0.2);
        background-color: #78A550;
        border: 0;
        margin: 0 auto;
    }
    .foto-button {
        width: 80px;
        height: 80px;
        background-color: rgba(230, 230, 230, 0.5);
        margin: 0 auto;
        border: none;
        position: relative;
        &:after {
            content: '';
            width: 56px;
            height: 56px;
            background-color: #7ecd21;
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &.-disabled {
            pointer-events: none;
            &:after {
                background-color: #bebebe;
            }
        }
    }
}

/*crop-foto*/
.crop-foto {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding-bottom: 20px;
    z-index: 50;

    .crop-foto-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56vh;
        margin-bottom: 40px;

        img {
            max-width: 315px;
            max-height: 100%;
        }
    }

    .crop-foto-container-1 {
        background-color: #74b9ff;
    }

    .crop-foto-container-2 {
        background-color: #ff7675;
    }

    .crop-foto-container-3 {
        background-color: #81ecec;
    }

    .crop-foto-container-4 {
        background-color: #fdcb6e;
    }

    h3 {
        font-size: 24px;
        font-weight: bold;
        color: #111;
        text-align: center;
        margin-bottom: 8px;

        span {
            color: #d63031;
        }
    }

    p {
        color: #999;
        margin-bottom: 40px;
    }

    .w-button {
        padding: 0 24px;
        margin-top: 40px;

        &>div {
            width: 100%;
        }
    }

    button,
    a {
        letter-spacing: 0.48px;
        height: 48px;
        max-width: 450px;
        box-shadow: none;
        box-sizing: border-box;
    }

    .next-button {
        color: #78A550;
        background-color: transparent;
        border: solid 2px #78A550;

        svg {
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
        }

        &:first-child {
            margin-bottom: 15px;
        }
    }

    .link {
        color: #fff;
        background-color: #78A550;
        border: solid 2px #78A550;
        margin: 0 auto;
        display: block;
    }
}

.capture-container {
    position: relative;
    display: flex;
    flex-shrink: 0;
    margin-bottom: 24px;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        //transform: translateY(-50%);
        //left: 0;
        //width: 100%;
        //height: 80%;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 62%;
        height: 62%;
        background: url("/assets/images/icon-user-crop2.png") center no-repeat;
        background-size: contain;
    }

    .capture-holder {
        margin: 0 auto;
        height: 100%;
        transform: scaleX(-1);
    }

    .capture-image {
        width: auto;
        height: 100%;
        display: block;
        margin: 0 auto;
        transform: scaleX(-1);
    }

    &.captured {
        &:after {
            opacity: 0;
        }

        .capture-holder {
            position: absolute;
            pointer-events: none;
            opacity: 0;
        }
    }

    .back-button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: 24px;
        left: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 35;

        svg {
            width: 20px;
        }
    }
}

.error-block {
    width: 100%;
    padding: 0 48px;
    background-color: rgba(17, 17, 17, 0.85);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 30;

    svg {
        width: 56px;
        margin-bottom: 24px;
    }

    h4 {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.4;
        color: #fff;
        margin-bottom: 7px;
    }

    button {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.48px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        border-radius: 48px;
        background-color: #7ecd21;
    }
}

.webview.android {
    .capture-container:after {
        width: 50%;
        height: 50%;
    }
}

/*cropper*/
.cropper {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    .notify-container {
        flex-grow: 1;
        padding: 0 24px;

        h3 {
            font-size: 12px;
            text-align: left;
            font-weight: normal;
            line-height: 1;
            color: #999;
            margin-bottom: 16px;
        }

        ul {
            display: flex;
            justify-content: space-between;
        }

        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 30%;
        }

        svg {
            width: 48px;
            margin-bottom: 8px;
        }

        p {
            font-size: 12px;
            line-height: 1.4;
            color: #222;
            text-transform: lowercase;
            padding: 0;
            margin: 0;
            text-align: center;
        }

        span {
            font-weight: bold;
        }

        .danger-item {
            span {
                color: #e7504e;
            }
        }

        .success-item {
            span {
                color: #78a550;
            }
        }

        .next-button {
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: 0.48px;
            color: #7ecd21;
            width: 100%;
            border: none;
            background-color: transparent;
        }
    }

    .w-button {
        margin-top: 10px;
        flex-shrink: 0;

        .next-button {
            color: #fff;
            background-color: #7ecd21;
            border: solid 2px #7ecd21;
            position: relative;
        }

        .icon-done {
            width: 16px;
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .css-bdjl4r {
        background-image: url('/assets/images/icon-user-crop.png');
        background-size: contain;
        background-position: center;
    }

    .css-1dkwqii {
        bottom: 150px;
    }

    .button-info {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 100;
        height: auto;
        display: flex;
        background: transparent;
        padding: 4px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .crop-buttons-container {
        position: absolute;
        bottom: 24px;
        z-index: 100;
        flex-direction: column;

        a {
            color: #fff;
            border-color: #fff;
            position: relative;
            margin-bottom: 16px;

            svg {
                position: absolute;
                top: 50%;
                left: 14px;
                transform: translateY(-50%);
            }
        }

        button {
            background-color: #78A550;
            color: #fff;

            &:first-child {
                border: solid 2px #ffffff;
                box-sizing: border-box;
                background-color: transparent;
            }
        }
    }

    .popup-info-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(17, 17, 17, 0.85);
        z-index: 110;
    }

    .popup-info {
        width: 100%;
        border-radius: 24px;
        background-color: #fff;
        box-sizing: border-box;
        padding: 24px 24px 8px 24px;
        margin-bottom: 16px;

        h4 {
            font-size: 24px;
            font-weight: bold;
            color: #111;
            margin-bottom: 18px;
        }

        li {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
        }

        span {
            color: #d63031;
        }

        svg {
            margin-right: 16px;
            margin-top: -3px;
        }

        p {
            font-weight: bold;
            color: #111;
            padding: 0;
            margin: 0;
        }
    }
}

.-show-popup {
    overflow: hidden;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    padding: 40px 4vw;
    background-color: #fff;
    width: 90%;
    max-width: 500px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    z-index: 10;
}

.modal-title {
    font-family: "Sego-Bold", cursive;
    font-size: 20px;
    color: #2c3945;
    text-align: center;
    margin-bottom: 32px;
}

.social-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    margin: 0 auto 32px;
}

.copy-mention {
    text-align: center;
}

.btn-social {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    flex-shrink: 0;
    margin: 0 8px;

    svg {
        width: 100%;
        height: 100%;
    }

    &-share {
        border: 2px solid #0c0613;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 66%;
            height: 66%;
        }
    }
}

.btn-close {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 4px;
    &:after,
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 8px;
        content: ' ';
        height: 17px;
        width: 1px;
        background-color: #aba8a8;
    }
    &:after {
        
        transform: rotate(-45deg);
    }
    &:before {
        transform: rotate(45deg);
    }
}

.icon-cat {
    position: absolute;
    left: -70px;
    bottom: -30px;
    width: 206px;
    z-index: -5;
}

form {
    height: 48px;
    max-width: 300px;
    position: relative;
    margin: 0 auto;
    input {
        font-size: 18px;
        color: #333;
        border: 1px solid #3f5c72;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        text-overflow: ellipsis;
        padding-left: 15px;
        padding-right: 100px;
    }
    button {
        font-size: 18px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0c0613;
        background-image: linear-gradient(34deg, rgba(37, 58, 75, 0.66) 0%, rgba(80, 125, 160, 0.66) 100%);
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        border-radius: 10px;
        padding: 0 16px;
    }
}

.insta-modal-container {
    .modal {
        overflow: hidden;
        max-height: 90%;
        padding: 0;
    }
    .social-container {
        flex-direction: column;
        overflow: auto;
        height: 100%;
        justify-content: flex-start;
        padding: 40px 4vw;
        margin-bottom: 0;
    }
    img {
        max-height: 220px;
        margin-bottom: 16px;
    }
    .btn-close {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 10px;
        right: 20px;
    }
}

@media all and (max-height: 660px) {
    .result-page .imageview-host {
        max-height: 410px;
    }
}

@media all and (max-height: 650px) {
    .crop-foto {

        .crop-foto-container,
        p {
            margin-bottom: 30px;
        }
    }
}

@media all and (max-height: 590px) {
    .result-page .imageview-host {
        max-height: 360px;
    }
}

@media all and (max-height: 590px) {
    .result-page .imageview-host {
        max-height: 300px;
    }
}

@media all and (max-width: 360px) {
    .crop-foto {
        .crop-foto-container {
            img {
                max-width: 280px;
            }
        }

        p,
        .crop-foto .w-button {
            padding: 0 20px;
        }
    }
}

@media all and (max-width: 350px) {
    .crop-foto {

        button,
        a {
            font-size: 14px;
            height: 44px;
            line-height: 40px;
        }

        .gallery-button {
            line-height: 1;
            height: auto;
            font-size: 12px;
        }

        .next-button svg {
            width: 16px;
            left: 10px;
        }
    }
}

@media all and (max-width: 340px) {
    .crop-foto {
        .crop-foto .w-button {
            padding: 0 10px;
        }

        .next-button svg {
            left: 10px;
        }
    }

    .cropper {
        padding-bottom: 10px;

        .notify-container {
            padding: 0 10px;
        }
    }
}

@media all and (max-height: 600px) {
    .crop-foto .crop-foto-container img {
        max-width: 280px;
    }
}

@media all and (max-height: 520px) and (max-width: 360px) {
    .crop-foto .crop-foto-container img {
        max-width: 220px;
    }
}