.imageview-host {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(0, 0, 0, .1);

  .imageview-holder {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .imageview-holder,
  .imageview-image {
    animation: imageview-show 320ms;
  }
}

@keyframes imageview-show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*loader*/
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  p {
    color: #868686;
  }

  &.android {
    flex-direction: column;

    p {
      margin-bottom: 14px;
    }
  }
}

.loader-ios {
  display: flex;
  margin-right: 24px;

  .item-loader {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    animation: item-loader .9s linear infinite;
    margin: 0 6px;
  }

  .first-item-loader {
    background-color: #1db8ff;
  }

  .second-item-loader {
    background-color: #ffd300;
    animation: item-loader .9s linear infinite;
    animation-delay: .3s;
  }

  .third-item-loader {
    background-color: #fc4700;
    animation: item-loader .9s linear infinite;
    animation-delay: .6s;
  }

  @keyframes item-loader {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.8, 1.8);
    }
  }
}

.loader-android {
  width: 200px;
  height: 2px;
  background-color: rgba(5, 176, 255, 0.2);
  position: relative;
  overflow: hidden;

  span {
    position: absolute;
    top: 0;
    height: 100%;
    width: 70px;
    background-color: #05b0ff;
    -webkit-animation: line-loader .9s linear infinite;
    animation: line-loader .9s linear infinite;
    z-index: 100;
  }

  @-webkit-keyframes line-loader {
    from {
      left: -70px;
    }

    to {
      left: 270px;
    }
  }

  @keyframes line-loader {
    from {
      left: -70px;
    }

    to {
      left: 270px;
    }
  }
}

.loader-android-2 { 
  width: 200px;
  height: 2px; 
  background-color: rgba(5, 176, 255, 0.2);
  position: relative;
}

.loaderBar { 
  position: absolute;
  top: 0;
  left: 0;
  background-color: #05b0ff;
  height: 100%;
  width: 0;
  -webkit-animation: borealisBar .9s linear infinite;
  animation: borealisBar .9s linear infinite;
}

@-webkit-keyframes borealisBar {
  0% {
    left: 0%;
    width: 0%;
  }
  7% {
    left: 0%;
    width: 10%;
  }
  14% {
    left: 0%;
    width: 20%;
  }
  21% {
    left: 0%;
    width: 30%;
  }
  28% {
    left: 10%;
    width: 30%;
  }
  35% {
    left: 20%;
    width: 30%;
  }
  42% {
    left: 30%;
    width: 30%;
  }
  49% {
    left: 40%;
    width: 30%;
  }
  56% {
    left: 50%;
    width: 30%;
  }
  63% {
    left: 60%;
    width: 30%;
  }
  70% {
    left: 70%;
    width: 30%;
  }
  77% {
    left: 80%;
    width: 20%;
  }
  84% {
    left: 90%;
    width: 10%;
  }
  91% {
    left: 100%;
    width: 0%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}

@keyframes borealisBar {
  0% {
    left: 0%;
    width: 0%;
  }
  7% {
    left: 0%;
    width: 10%;
  }
  14% {
    left: 0%;
    width: 20%;
  }
  21% {
    left: 0%;
    width: 30%;
  }
  28% {
    left: 10%;
    width: 30%;
  }
  35% {
    left: 20%;
    width: 30%;
  }
  42% {
    left: 30%;
    width: 30%;
  }
  49% {
    left: 40%;
    width: 30%;
  }
  56% {
    left: 50%;
    width: 30%;
  }
  63% {
    left: 60%;
    width: 30%;
  }
  70% {
    left: 70%;
    width: 30%;
  }
  77% {
    left: 80%;
    width: 20%;
  }
  84% {
    left: 90%;
    width: 10%;
  }
  91% {
    left: 100%;
    width: 0%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}